import React from "react";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  FormHelperText,
  Stack,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import signInImage from "assets/img/signInImage.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import constant from "constant";
import MainLogo from "assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import Footer from "components/Footer/Footer";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const toast = useToast();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isLoginLoading = useSelector(state => state.loading?.effects?.authModel.login);

  const onSubmit = (data) => {
    const { username, password } = data;
    dispatch.authModel.login({
      username,
      password,
      success: () => {
        toast({
          title: "Login Successful",
          status: "success",
          position: "top",
        });
        navigate("/admin/users");
      },
      error: (message) => {
        toast({
          title: "Login Error",
          description: message,
          status: "error",
          position: "top",
        });
      },
    })
  };

  return (
    <Box>
      <Flex
        position="relative"
        mb="40px"
        h={{ sm: "90vh", md: "75vh", lg: "85vh" }}
      >
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mb="60px"
        >
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={"navy.800"}
          >
            <Stack
              direction="row"
              spacing="12px"
              align="center"
              justify="center"
              mb="15px"
            >
              <Avatar
                size="md"
                src={MainLogo}
                background={"black"}
                boxSize="40px"
              />
              <Text
                bgGradient={constant.globalTheme.textGradient}
                fontWeight={"bold"}
                bgClip="text"
                fontSize={"18px"}
              >
                3 COMMAS CLUB
              </Text>
            </Stack>
            <Text
              fontSize="xl"
              color={"white"}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
            >
              Admin Login
            </Text>

            <FormControl id="username" isRequired>
              <FormLabel fontSize="sm">Username</FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                type="text"
                placeholder="Enter your username"
                size="lg"
                {...register("username", {
                  required: "Please enter username.",
                  // pattern: {
                  //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //   message: "Please enter a valid email.",
                  // },
                })}
              />
              {/* {errors.email && (
                <FormHelperText color="red">
                  {errors.email.message}
                </FormHelperText>
              )} */}
              <FormLabel id="password" fontSize="sm" mt="24px">
                Password
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                type="password"
                placeholder="Enter your password"
                size="lg"
                {...register("password", {
                  required: "Please enter password.",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })}
              />
              {errors.password && (
                <FormHelperText color="red">
                  {errors.password.message}
                </FormHelperText>
              )}

              <Button
                variant="dark"
                fontWeight="bold"
                w="100%"
                my="24px"
                textTransform={"uppercase"}
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoginLoading}
              >
                Login
              </Button>
            </FormControl>
          </Flex>
        </Flex>

        {/* BACKGROUND IMAGE */}
        <Box
          overflowX="hidden"
          h="100%"
          w="100%"
          position="absolute"
          bgImage={signInImage}
        >
          <Box w="100%" h="100%" bgSize="cover" bg="blue.500" opacity="0.8" />
        </Box>
      </Flex>
      <Box px="24px" mx="auto" width="1044px" maxW="100%" mt="60px">
        <Footer />
      </Box>
    </Box>
  );
}

export default connect()(Login);
