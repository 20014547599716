import {
  Badge,
  Box,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import colors from "utils/colors";
import { BiLinkExternal } from "react-icons/bi";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

const NftItem = ({ nft, onItemDelete, onItemEdit }) => {
  const { _id, name, imgUrl, url, status, createdAt } = nft || {};

  const badgeColor = (() => {
    switch (status) {
      case "open":
        return "purple";
      case "reserved":
        return "red";
      case "sold":
        return "green";
      default:
        return "";
    }
  })();

  return (
    <Stack
      direction="column"
      align="center"
      justify="center"
      bgColor="gray.600"
      py={5}
      borderRadius="8px"
      spacing={4}
    >
      <Box boxSize="150px" p={2} bgColor="whiteAlpha.500" borderRadius="8px">
        <Image src={imgUrl} />
      </Box>

      <Stack justify='center' align='center' spacing={1}>
        <Text fontSize="sm" color={colors.white} fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="xs" color={'gray.400'}>
          {_id}
        </Text>
      </Stack>

      <HStack>
        <Badge colorScheme={badgeColor} fontSize="sm" borderRadius="5px">
          {status}
        </Badge>
        <Link href={url} isExternal>
          <BiLinkExternal size={20} />
        </Link>
      </HStack>

      <HStack spacing={5}>
        <AiFillDelete size={20} onClick={onItemDelete} cursor="pointer" />
        <AiFillEdit size={20} onClick={onItemEdit} cursor="pointer" />
      </HStack>
    </Stack>
  );
};

export default NftItem;
