import {
  Flex,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import OrderItemModal from "components/Modal/OrderItemModal";
import OrderRow from "components/Tables/OrderRow";
import TableTitle from "components/Tables/TableTitle";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

const mapState = ({ orderModel }) => ({
  orderList: orderModel.orderList,
});

const Orders = ({ orderList }) => {
  const dispatch = useDispatch();
  const isOrderLoading = useSelector(
    (state) => state.loading.effects?.orderModel?.getAllOrders || false
  );

  useEffect(() => {
    dispatch.orderModel.getAllOrders();
  }, []);

  const [selectedItem, setSelectedItem] = useState(null);
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color="white" fontWeight="bold">
            Orders
          </Text>
        </CardHeader>
        <CardBody>
          {isOrderLoading ? (
            <Spinner />
          ) : (
            <Table variant={"simple"} color="white">
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <TableTitle isFirst title="Order" />
                  <TableTitle title="Items" />
                  <TableTitle title="Payment" />
                  <TableTitle title="Shipping" />
                </Tr>
              </Thead>
              <Tbody>
                {orderList?.map((row, index, arr) => {
                  return (
                    <OrderRow
                      key={index}
                      order={row}
                      isLast={index === arr.length - 1}
                    />
                  );
                })}
              </Tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default connect(mapState)(Orders);
