import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  DrawerOverlay,
  DrawerFooter,
  Text,
  Box,
  IconButton,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import DisplayText from "components/Common/DisplayText";

const AddNFTDrawer = ({ isOpen, onClose, btnRef, onSubmit }) => {
  const [list, setList] = useState([]);

  const toast = useToast();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });

  const onItemAdd = (data) => {
    setList([...list, data]);
    reset({
      name: "",
      imgUrl: "",
      url: "",
    });
  };

  const onItemDelete = (d) => {
    setList(list.filter((item) => item.url !== d.url));
  };

  const onItemSubmit = () => {
    if (isEmpty(list)) {
      toast({
        description: "Please add some NFT item before confirm",
        status: "error",
      });
      return;
    }

    onSubmit(list);
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      finalFocusRef={btnRef}
      closeOnOverlayClick="false"
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent bg="navy.800">
        <DrawerCloseButton onClick={onClose} />
        <DrawerHeader>Add NFTs</DrawerHeader>
        <DrawerBody>
          <Stack>
            <FormControl isRequired size="sm">
              <FormLabel color="white" fontSize="sm">
                Name
              </FormLabel>
              <Input
                color="white"
                size={"sm"}
                placeholder="Enter NFT's name"
                {...register("name", {
                  required: "Please enter NFT's name",
                })}
              />
              {errors.name && (
                <FormHelperText color="red" fontSize={"sm"}>
                  {errors.name.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white" fontSize="sm">
                Image URL
              </FormLabel>
              <Input
                size={"sm"}
                color="white"
                placeholder="Enter NFT's Image URL"
                {...register("imgUrl", {
                  required: "Please enter Image URL",
                })}
              />
              {errors.imgUrl && (
                <FormHelperText color="red.500">
                  {errors.imgUrl.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="white" fontSize="sm">
                URL
              </FormLabel>
              <Input
                color="white"
                size={"sm"}
                placeholder="Enter NFT's URL"
                {...register("url", {
                  required: "Please enter NFT's name",
                })}
              />
              {errors.url && (
                <FormHelperText color="red" fontSize="sm">
                  {errors.url.message}
                </FormHelperText>
              )}
            </FormControl>
            <Button colorScheme={"blue"} onClick={handleSubmit(onItemAdd)}>
              Add
            </Button>
          </Stack>

          {!isEmpty(list) && (
            <Box>
              <Text fontWeight="bold" mt={2}>
                NFT List
              </Text>
              {list.map((d) => {
                return (
                  <Flex
                    key={d.imgUrl}
                    bg="whiteAlpha.300"
                    my={3}
                    borderRadius="8px"
                    p={2}
                    justify="space-between"
                    align="center"
                  >
                    <Box maxW="80%">
                      <DisplayText label="Name" value={d.name} fontSize="sm" />
                      <DisplayText
                        label="Image URL"
                        value={d.imgUrl}
                        fontSize="sm"
                        style={{
                          wordWrap: "break-word",
                        }}
                      />
                      <DisplayText
                        label="URL"
                        value={d.url}
                        fontSize="sm"
                        style={{
                          wordWrap: "break-word",
                        }}
                      />
                    </Box>
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={() => onItemDelete(d)}
                    />
                  </Flex>
                );
              })}
            </Box>
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button onClick={onClose} mr={3}>
            Close
          </Button>

          <Button colorScheme="red" onClick={onItemSubmit}>
            Confirm
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddNFTDrawer;
