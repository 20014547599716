const globalTheme = {
  textGradient: "linear(to-l, #1cb8f5, #9357D3, #FF00B4)",
  bgBoxGradient: "linear(to-b, #FF00B4, rgb(0,0,0,0))",
  bgBoxGradient2: "linear(to-b, #FF00B4, #1CB8F5)",
  buttonGradient: 'linear(to-r, #1CB8F5, #E516BC)',
  primaryColor: '#FF00B4'
};


const cardDesigns = [
  {
    id: 1,
    cardImg: '/card1.png',
    title: 'Premium Matte',
  },
  {
    id: 2,
    cardImg: '/card2.png',
    title: 'Premium Matte',
  },
  {
    id: 3,
    cardImg: '/card3.png',
    title: 'Premium Matte',
  },
  {
    id: 4,
    cardImg: '/card4.png',
    title: 'Premium Matte',
  },
  {
    id: 5,
    cardImg: '/card5.png',
    title: 'Premium Matte',
  },
  {
    id: 6,
    cardImg: '/card6.png',
    title: 'Premium Matte',
  },
]

const cashoutStatus = [
  {
    title: 'success',
    color: 'green',
  },
  {
    title: 'failed',
    color: 'red'
  },
  {
    title: 'pending',
    color: 'purple'
  }
]

export default {
  globalTheme,
  cardDesigns,
  cashoutStatus
}