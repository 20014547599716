import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import MainPage from 'pages';
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme.js";
import store from 'store';

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme} resetCss={false} position="relative">
      <MainPage/>
    </ChakraProvider>
  </Provider>,
 
  document.getElementById("root")
);
