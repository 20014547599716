import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import Login from "./Auth/Login";
import { connect } from "react-redux";
import Dashboard from "./Dashboard/Dashboard";
import Users from "./Dashboard/Users";
import Tables from "./Dashboard/Tables";
import Billing from "./Dashboard/Billing";
import Profile from "./Dashboard/Profile";
import Orders from "./Dashboard/Orders";
import Nfts from "./Dashboard/Nfts";
import Cashout from "./Dashboard/Cashout";
import NavigateSetter from "components/Routing/NavigateSetter";

const mapState = ({ authModel }) => ({
  token: authModel.token,
});

const MainPage = ({ token }) => {
  return (
    <BrowserRouter>
      <NavigateSetter />
      <Routes>
        <Route path={`/admin`} element={token ? <AdminLayout /> : <Login />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="orders" element={<Orders />} />
          <Route path="nfts" element={<Nfts />} />
          <Route path="cashout" element={<Cashout />} />
          <Route path="tables" element={<Tables />} />
          <Route path="billing" element={<Billing />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path={`/auth`} element={<Login />} />
        <Route path={"/"} element={token ? <AdminLayout /> : <Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default connect(mapState)(MainPage);
