import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Stack,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const EditNFTModal = ({ nft, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const isEditLoading = useSelector(
    (state) => state.loading.effects.nftModel.editNFT
  );
  const { imgUrl, url, name, status, _id } = nft || {};
  console.log('edit', nft);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      imgUrl,
      url,
      name,
      status,
      id: _id,
    },
  });

  const onSubmit = (value) => {
    dispatch.nftModel.editNFT({
      id: value.id,
      newData: {
        name: value.name,
        url: value.url,
        imgUrl: value.imgUrl,
        status: value.status,
      },
      success: () => {
        reset();
        onClose();
        dispatch.nftModel.getNFTCollectionList();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit NFT</ModalHeader>
        <ModalCloseButton
          color="white"
          onClick={() => {
            reset();
            onClose();
          }}
        />
        <ModalBody my={5}>
          <Stack>
            <FormControl isRequired size="sm">
              <FormLabel color="white" fontSize="sm">
                Name
              </FormLabel>
              <Input
                color="white"
                size={"sm"}
                placeholder="Enter NFT's name"
                {...register("name", {
                  required: "Please enter NFT's name",
                })}
              />
              {errors.name && (
                <FormHelperText color="red" fontSize={"sm"}>
                  {errors.name.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired size="sm">
              <FormLabel color="white" fontSize="sm">
                URL
              </FormLabel>
              <Textarea
                color="white"
                size={"sm"}
                placeholder="Enter NFT's URL"
                {...register("url", {
                  required: "Please enter NFT's URL",
                })}
              />
              {errors.url && (
                <FormHelperText color="red" fontSize={"sm"}>
                  {errors.url.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired size="sm">
              <FormLabel color="white" fontSize="sm">
                Image URL
              </FormLabel>
              <Textarea
                color="white"
                size={"sm"}
                placeholder="Enter NFT's Image URL"
                {...register("imgUrl", {
                  required: "Please enter NFT's Image URL",
                })}
              />
              {errors.imgUrl && (
                <FormHelperText color="red" fontSize={"sm"}>
                  {errors.imgUrl.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl isRequired size="sm">
              <FormLabel color="white" fontSize="sm">
                Status
              </FormLabel>
              <Select
                size="sm"
                {...register("status", {
                  required: "Please enter NFT's status",
                })}
              >
                <option value="open">Open</option>
                <option value="reserved">Reserved</option>
                <option value="sold">Sold</option>
              </Select>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="green"
            onClick={handleSubmit(onSubmit)}
            isLoading={isEditLoading}
          >
            Edit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditNFTModal;
