import React from "react";
import {
  Flex,
  Stack,
  Box,
  Image,
  Text,
  HStack,
  Divider,
  Link,
} from "@chakra-ui/react";
import DisplayText from "components/Common/DisplayText";
import dayjs from "dayjs";
import constant from "constant";

const OrderItemRow = ({ item }) => {
  const {
    package: cardPackage,
    backupCard,
    matte,
    color,
    name,
    position,
    company,
    nft,
    total,
    design,
  } = item || {};

  const card = constant.cardDesigns.find((e) => e.id === design);

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing={5}
      justify="space-between"
      align="center"
      my={5}
      bgColor='navy.600'
      p={3}
      borderRadius='8px'
    >
      <Stack direction={{ base: "column", md: "row" }} spacing={5}>
        <Box
        borderRadius={"8px"}
        align="center"
      >
        <Image src={card?.cardImg} h="150px" />
        <Text color={"white"} fontWeight="bold">
          {card?.title}
        </Text>
      </Box>

        <Stack spacing={1} textAlign="left">
          <Text textTransform={"capitalize"}>
            <b>Card Package: </b>
            {cardPackage?.name}
          </Text>
          <Text>
            <b>Backup Card: </b>
            {backupCard ? "Yes" : "No"}
          </Text>
          <HStack>
            <Text fontWeight={"bold"}>Matte:</Text>
            <Box
              bgColor={matte}
              boxSize="20px"
              borderRadius="2px"
              border="1px solid #d9d9d9"
            />
          </HStack>

          <Text textTransform={"capitalize"}>
            <b>Text color: </b>
            {color}
          </Text>
          <Divider bgColor="white" />
          <Text>
            <b>Name:</b> {name}
          </Text>
          {position && (
            <Text>
              <b>Position:</b> {position}
            </Text>
          )}
          {company && (
            <Text>
              <b>Company:</b> {company}
            </Text>
          )}
        </Stack>

        <Box justify="center" align="center">
          <Text fontWeight="bold" mb={2}>
            NFT Collection
          </Text>
          <Link href={nft?.url} isExternal>
            <Box
              boxSize="150px"
              border="1px solid #d9d9d9"
              borderRadius="8px"
              p={2}
            >
              <Image src={nft?.imgUrl} />
            </Box>
          </Link>
        </Box>

        <Text
          fontWeight="bold"
          textAlign="right"
        >{`RM ${total}`}</Text>
      </Stack>
    </Stack>
  );
};

export default OrderItemRow;
