// Chakra imports
import {
  Portal,
  useDisclosure,
  Stack,
  Box,
  Avatar,
  Text,
} from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
import MainLogo from "assets/img/logo.png";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useState } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import bgAdmin from "assets/img/admin-background.png";
import constant from "constant";

function AdminLayout({ children }) {
  const [fixed, setFixed] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Box
        minH="40vh"
        w="100%"
        position="absolute"
        bg={"navy.900"}
        bgSize="cover"
        top="0"
      />

      <Sidebar
        routes={routes}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            <Avatar size="md" src={MainLogo} background={"black"} />
            <Text
              bgGradient={constant.globalTheme.textGradient}
              fontWeight={"bold"}
              bgClip="text"
              fontSize={"18px"}
            >
              3 COMMAS CLUB
            </Text>
          </Stack>
        }
        display="none"
      />

      <MainPanel
        w={{
          base: "100%",
          md: "calc(100% - 275px)",
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={"BRAND TEXT"}
            secondary={"SECONDARY"}
            fixed={fixed}
            // {...rest}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            <Outlet />
          </PanelContainer>
        </PanelContent>
        <Footer />
        {/* <Portal>
          <FixedPlugin secondary={"SECONDARY"} fixed={fixed} onOpen={onOpen} />
        </Portal> */}
        {/* <Configurator
          secondary={"SECONDARY"}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          onSwitch={(value) => {
            setFixed(value);
          }}
        /> */}
      </MainPanel>
    </Box>
  );
}

export default AdminLayout;
