import { createStandaloneToast } from "@chakra-ui/react";
import request from "utils/request";

const ApiKey = process.env.REACT_APP_API_KEY;

const toast = createStandaloneToast();

export default {
  state: {
    nftList: [],
  },
  reducers: {
    updateNFTList: (state, payload) => {
      const { data } = payload;
      return { ...state, nftList: data };
    },
  },
  effects: (dispatch) => ({
    async getNFTCollectionList() {
      const res = await request(`/api/v1/nfts`, {
        headers: {
          "X-API-KEY": ApiKey,
        },
      });

      const { status, data } = res;

      if (status === 200) {
        this.updateNFTList({ data });
      }
    },

    async deleteNFT(payload) {
      const { id, success } = payload;

      const res = await request(`/api/v1/nfts/${id}`, {
        method: "delete",
      });

      const { status, data } = res;

      if (status === 200) {
        success();
      } else {
        const { statusCode, message } = res.data || {};
        toast({
          title: "Delete Error",
          description: message,
          status: "error",
          position: "top",
        });
      }
    },

    async addNFTList(payload) {
      const { list, success } = payload;

      const res = await request("/api/v1/nfts", {
        method: "POST",
        data: {
          nfts: list,
        },
      });

      const { status, data } = res;

      if (status === 200) {
        success();
      }
    },

    async editNFT(payload) {
      const {id, newData, success} = payload;

      const res = await request(`/api/v1/nfts/${id}`, {
        method: "PUT",
        data: newData,
      });

      const {status, data} = res;
      if(status === 200) {
        success();
      }
     
    }
  }),
};
