import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import OrderItemRow from "components/Tables/OrderItemRow";
import React from "react";

const OrderItemModal = ({ items, setItems, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent minW="70vw">
        <ModalHeader color="white">Items</ModalHeader>
        <ModalCloseButton
          color="white"
          onClick={() => {
            setItems(null);
            onClose();
          }}
        />
        <ModalBody
          maxH={"300px"}
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#d9d9d9",
              borderRadius: "24px",
            },
          }}
        >
          {items?.map((item) => {
            return <OrderItemRow key={item._id} item={item} />;
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OrderItemModal;
