import request from "utils/request.js";
import { createBrowserHistory } from "history";

const baseUrl = process.env.REACT_APP_API_URL;

export default {
  state: {
    orderList: [],
  },
  reducers: {
    updateOrderList: (state, payload) => {
      const { data } = payload;
      return { ...state, orderList: data };
    },
  },
  effects: (dispatch) => ({
    async getAllOrders(payload) {
      const res = await request("/api/v1/business-card-orders");

      const { status, data: resData } = res;

      if (status === 200) {
        const { data } = resData;
        this.updateOrderList({ data });
      }
    },
  }),
};
