import { Th } from "@chakra-ui/react";
import React from "react";

const TableTitle = ({ title, isFirst }) => {
  return (
    <Th pl={isFirst ? "0px" : null} borderColor={"gray.600"} color="gray.400">
      {title}
    </Th>
  );
};

export default TableTitle;
