import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";

const CustomAlertDialog = ({
  title,
  subtitle,
  cancelText,
  submitText,
  visible,
  onClose,
  onSubmit,
}) => {
  return (
    <AlertDialog isOpen={visible} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{subtitle}</AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}> {cancelText || "Cancel"}</Button>
            <Button colorScheme="red" onClick={onSubmit} ml={3}>
              {submitText || "Ok"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CustomAlertDialog;
