import {init} from '@rematch/core';
import userModel from './models/user';
import authModel from './models/auth';
import nftModel from './models/nft';
import orderModel from './models/orders';
import cashoutModel from './models/cashout';
import storage from 'redux-persist/lib/storage';
import persistPlugin from "@rematch/persist";
import loadingPlugin from '@rematch/loading';

const persistConfig = {
  key: 'root', 
  storage,
}

const store = init({
  models: {
    userModel,
    authModel,
    nftModel,
    orderModel,
    cashoutModel,
  },
  plugins:[
    persistPlugin(persistConfig),
    loadingPlugin(),
  ],
  redux: {
    rootReducers: {
      RESET_ALL_STATE: () => undefined
    }
  }
})

export default store;