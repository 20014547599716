import {
  Flex,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableTitle from "components/Tables/TableTitle";
import UsersRow from "components/Tables/UsersRow";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

const mapState = ({ userModel }) => ({
  userList: userModel.userList,
});

const Users = ({ userList }) => {
  const dispatch = useDispatch();
  const isUserLoading = useSelector(
    (state) => state.loading.effects.userModel.getAllUsers
  );

  useEffect(() => {
    dispatch.userModel.getAllUsers();
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color="white" fontWeight="bold">
            Users
          </Text>
        </CardHeader>
        <CardBody>
          {isUserLoading ? (
            <Spinner />
          ) : (
            <Table variant={"simple"} color="white">
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <TableTitle isFirst title="User" />
                  {/* <TableTitle title="Details" /> */}
                  <TableTitle title="Wallet" />
                  <TableTitle title="Refferal Code" />
                  <TableTitle title="Business Profile" />
                </Tr>
              </Thead>
              <Tbody>
                {userList?.map((row, index, arr) => {
                  return (
                    <UsersRow
                      key={index}
                      user={row}
                      isLast={index === arr.length - 1}
                    />
                  );
                })}
              </Tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default connect(mapState)(Users);
