import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import colors from "utils/colors";
import { BiLinkExternal } from "react-icons/bi";
import DisplayText from "components/Common/DisplayText";

const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

const UsersRow = ({ user, isLast }) => {
  const {
    _id,
    username,
    cryptoWallet,
    referralCode,
    email,
    status,
    emailVerified,
    lastLoginAt,
    wallet,
    googleId,
  } = user || {};
  const { cash, credit, transferableCredit } = wallet || {};

  const bpProfile = `${frontendUrl}/profile/${username}`;

  return (
    <Tr>
      <Td
        pl="0px"
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          {/* <Avatar src={logo} w="50px" borderRadius="12px" me="18px" /> */}
          <Flex direction="column">
            <Text
              as={"span"}
              fontSize="md"
              color="white"
              fontWeight="bold"
              minWidth="100%"
            >
              {username}
            </Text>
            <Box>
              <Text
                as={"span"}
                fontWeight="bold"
                color={"gray.200"}
                fontSize="sm"
              >
                ID:
              </Text>{" "}
              <Text as={"span"} fontSize="sm" color={"gray.400"}>
                {_id}
              </Text>
            </Box>
            <Box>
              <Text
                as={"span"}
                fontWeight="bold"
                color={"gray.200"}
                fontSize="sm"
              >
                Email:
              </Text>{" "}
              <Text as={"span"} fontSize="sm" color={"gray.400"}>
                {email}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Td>

      <Td
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Box>
          <DisplayText label="Cash" value={cash} fontSize="sm" />
          <DisplayText label="Credit" value={credit} fontSize="sm" />
          <DisplayText
            label="Transferrable Credit"
            value={transferableCredit}
            fontSize="sm"
          />
        </Box>
      </Td>

      <Td
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Box>
          <Text fontSize="sm">{referralCode}</Text>
        </Box>
      </Td>

      <Td
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <HStack>
          <Link
            href={bpProfile}
            isExternal
            style={{ wordBreak: "break-word" }}
            fontSize="sm"
          >
            {bpProfile}
          </Link>
          <BiLinkExternal size={20} />
        </HStack>
      </Td>
    </Tr>
  );
};

export default UsersRow;
