import request from 'utils/request.js';
import { createBrowserHistory } from 'history';

const baseUrl = process.env.REACT_APP_API_URL;

export default {
  state: {
  },
  reducers: {
    updateProfile: (state, payload) => {
      const { token, username, user_id, email } = payload;
      return { ...state, token, username, user_id, email };
    },
  },
  effects: (dispatch) => ({
    async login(payload) {
      console.log('login', baseUrl);
      const { username, password, success, error } = payload;
      const res = await request(`/api/v1/auth/user-admin/login`, {
        method: "POST",
        data: {
          username,
          password,
        },
      });
      const { status, data } = res;

      if (status === 200) {
        this.updateProfile(data);
        success();
      } else {
        const { message } = data || {};
        error(message);
      }
    },
  }),
};