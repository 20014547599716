import { createStandaloneToast } from "@chakra-ui/react";
import request from "utils/request.js";

const toast = createStandaloneToast();

export default {
  state: {
    cashoutList: [],
  },
  reducers: {
    updateCashoutList: (state, payload) => {
      const { data } = payload;
      return { ...state, cashoutList: data };
    },
  },
  effects: (dispatch) => ({
    async getAllCashout(payload) {
      const res = await request("/api/v1/cash-outs");

      const { status, data: resData } = res;

      if (status === 200) {
        const { data } = resData;
        this.updateCashoutList({ data });
      }
    },

    async patchCashoutSuccess(payload) {
      const {transferAt, id, success} = payload;

      const res = await request(`/api/v1/cash-outs/${id}/success`, {
        method: "PATCH",
        data: {transferAt}
      })

      const {status, data} = res;

      if(status === 200) {
        success();
      } else {
        const { statusCode, message } = res.data || {};
        toast({
          title: "Patch Cashout Success Error",
          description: message,
          status: "error",
          position: "top",
        });
      }
    },

    async patchCashoutFailed(payload) {
      const {remark, id, success} = payload;
      
      const res = await request(`/api/v1/cash-outs/${id}/failed`, {
        method: "PATCH",
        // data: {remark}
      })

      const {status, data} = res;

      if(status === 200) {
        success();
      } else {
        const { statusCode, message } = res.data || {};
        toast({
          title: "Patch Cashout Failed Error",
          description: message,
          status: "error",
          position: "top",
        });
      }
    }
  }),
};
