import request from "../../utils/request";

const baseUrl = process.env.API_URL;

export default {
  state: {
    userList: [],
  },
  reducers: {
    updateUserList: (state, payload) => {
      const { data } = payload;
      return { ...state, userList: data };
    },
  },
  effects: (dispatch) => ({
    async getAllUsers() {
      const res = await request(`/api/v1/users`);

      if (res.status === 200) {
        this.updateUserList({ data: res.data });
      }
    },
  }),
};
