import {
  Flex,
  IconButton,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  Box,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import NftItem from "components/Common/NftItem";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import request from "utils/request";
import useSWR from "swr";
import CustomAlertDialog from "components/Modal/CustomAlertDialog";
import AddNFTDrawer from "components/Drawer/AddNFTDrawer";
import EditNFTModal from "components/Modal/EditNFTModal";

const mapState = ({ nftModel }) => ({
  nftList: nftModel.nftList,
});

const Nfts = ({ nftList }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [selected, setSelected] = useState(null);

  const isNftLoading = useSelector(
    (state) => state.loading?.effects?.nftModel?.getNFTCollectionList
  );

  const addBtnRef = useRef();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  // const fetcher = (url) => request(url);
  // const { data: resData, error } = useSWR("/api/v1/nfts", fetcher);
  // const { data } = resData || {};

  useEffect(() => {
    dispatch.nftModel.getNFTCollectionList();
  }, []);

  const onNftDelete = () => {
    dispatch.nftModel.deleteNFT({
      id: selected._id,
      success: () => {
        onDeleteClose();
        setSelected(null);
        dispatch.nftModel.getNFTCollectionList();
      },
    });
  };

  const onNftAdd = (list) => {
    dispatch.nftModel.addNFTList({
      list,
      success: () => {
        onAddClose();
        dispatch.nftModel.getNFTCollectionList();
      },
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card>
        <CardHeader p="6px 0px 22px 0px">
          <Flex justify="space-between" align="center">
            <Text fontSize="xl" color="white" fontWeight="bold">
              NFTs
            </Text>
            <IconButton
              icon={<AddIcon ref={addBtnRef} />}
              onClick={onAddOpen}
            />
          </Flex>
        </CardHeader>
        <CardBody>
          {isNftLoading ? (
            <Spinner />
          ) : (
            <SimpleGrid columns={[1, 2, 2, 3, 3, 4]} gap={5}>
              {nftList?.map((nft) => {
                return (
                  <NftItem
                    key={nft._id}
                    nft={nft}
                    onItemDelete={() => {
                      setSelected(nft);
                      onDeleteOpen();
                    }}
                    onItemEdit={() => {
                      setSelected(nft);
                      onEditOpen();
                    }}
                  />
                );
              })}
            </SimpleGrid>
          )}
        </CardBody>
      </Card>

      {isDeleteOpen && (
        <CustomAlertDialog
          visible={isDeleteOpen}
          title="Delete NFT"
          subtitle="Are you sure to delete this NFT? You cannnot undo this action afterwards"
          onClose={onDeleteClose}
          onSubmit={onNftDelete}
        />
      )}

      {isAddOpen && (
        <AddNFTDrawer
          isOpen={isAddOpen}
          onClose={onAddClose}
          btnRef={addBtnRef}
          onSubmit={onNftAdd}
        />
      )}

      {isEditOpen && (
        <EditNFTModal
          nft={selected}
          isOpen={isEditOpen}
          onClose={onEditClose}
        />
      )}
    </Flex>
  );
};

export default connect(mapState)(Nfts);
