import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";

export default function Footer() {
  return (
    <Flex
      justifyContent='center'
      px='30px'
      pb='20px'>
      <Text
        color='gray.400'
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        &copy; {1900 + new Date().getYear()}{" "}
        <Text as='span'>
          3CommasClub
        </Text>{" "}
        <Link
          color='blue.400'
          href='https://www.3commasnft.com'
          isExternal>
          Websites
        </Link>
      </Text>
    </Flex>
  );
}
