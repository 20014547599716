import {
  Td,
  Tr,
  Text,
  Button,
  Stack,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import DisplayText from "components/Common/DisplayText";
import constant from "../../constant";
import dayjs from "dayjs";
import React from "react";
import colors from "utils/colors";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";

const CashoutRow = ({ cashout, isLast }) => {
  const dispatch = useDispatch();
  const { _id, recipient, amount, status, createdAt } = cashout || {};

  const badgeStatus = constant.cashoutStatus.find((v) => v.title === status);

  return (
    <Tr>
      <Td
        pl="0px"
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <DisplayText label="ID" value={_id} fontSize="sm" />
        <DisplayText
          label="Created At"
          value={dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")}
          fontSize="sm"
        />
      </Td>

      <Td
        pl="0px"
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <DisplayText label="Name" value={recipient?.name} fontSize="sm" />
        <DisplayText
          label="Account"
          value={recipient?.accountNumber}
          fontSize="sm"
        />
        <DisplayText label="Bank" value={recipient?.bank} fontSize="sm" />
      </Td>

      <Td
        pl="0px"
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Text>{amount}</Text>
      </Td>

      <Td
        pl="0px"
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Badge colorScheme={badgeStatus.color}>{badgeStatus.title}</Badge>
      </Td>

      <Td
        pl="0px"
        borderColor={colors.borderColor}
        borderBottom={isLast ? "none" : null}
      >
        {status === "pending" && (
          <Menu size={"20px"}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Status
            </MenuButton>

            <MenuList>
              <MenuItem
                onClick={() => {
                  dispatch.cashoutModel.patchCashoutSuccess({
                    transferAt: dayjs().format(),
                    id: _id,
                    success: () => {
                      dispatch.cashoutModel.getAllCashout();
                    },
                  });
                }}
              >
                Success
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch.cashoutModel.patchCashoutFailed({
                    id: _id,
                    success: () => {
                      dispatch.cashoutModel.getAllCashout();
                    },
                  });
                }}
              >Fail</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  );
};

export default CashoutRow;
