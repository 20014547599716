import { BellIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BiLogOut } from "react-icons/bi";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
import { ItemContent } from "components/Menu/ItemContent";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import MainLogo from "assets/img/logo.png";
import constant from "constant";
import React from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";

function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    onLogout,
    ...rest
  } = props;

  let navbarIcon = fixed && scrolled ? "gray.200" : "gray.200";
  let menuBg = "navy.800";
  if (secondary) {
    navbarIcon = "white";
  }

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {/* <SearchBar me="18px" /> */}

      <Button
        display={{ sm: "none", md: "flex" }}
        onClick={onLogout}
        color={navbarIcon}
        variant='dark'
        leftIcon={<BiLogOut color={navbarIcon} size="25px" />}
      >
        <Text display={{ sm: "none", md: "flex" }}>Logout</Text>
      </Button>

      <SidebarResponsive
        hamburgerColor={"white"}
        onLogout={onLogout}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            <Avatar size="md" src={MainLogo} background={"black"} />
            <Text
              bgGradient={constant.globalTheme.textGradient}
              fontWeight={"bold"}
              bgClip="text"
              fontSize={"18px"}
            >
              3 COMMAS CLUB
            </Text>
          </Stack>
        }
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
    </Flex>
  );
}

export default HeaderLinks;
