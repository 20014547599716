import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CashoutRow from "components/Tables/CashoutRow";
import TableTitle from "components/Tables/TableTitle";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

const mapState = ({ cashoutModel }) => ({
  cashoutList: cashoutModel.cashoutList,
});

const Cashout = ({ cashoutList }) => {
  const dispatch = useDispatch();
  const isCashoutLoading = useSelector(
    (state) => state.loading.effects?.cashoutModel?.getAllCashout
  );

  useEffect(() => {
    dispatch.cashoutModel.getAllCashout();
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Text fontSize="xl" color="white" fontWeight="bold">
          Cashout History
        </Text>
        <CardBody>
          {isCashoutLoading ? (
            <Spinner />
          ) : (
            <Table variant={"simple"} color="white">
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <TableTitle isFirst title="ID" />
                  <TableTitle isFirst title="Recipient" />
                  <TableTitle isFirst title="Amount" />
                  <TableTitle isFirst title="Status" />
                  <TableTitle isFirst title="Action" />
                </Tr>
              </Thead>
              <Tbody>
                {cashoutList?.map((row, index, arr) => {
                  return (
                    <CashoutRow
                      key={index}
                      cashout={row}
                      isLast={index === arr.length - 1}
                    />
                  );
                })}
              </Tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default connect(mapState)(Cashout);
