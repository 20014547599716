import { Td, Tr, Text, Box, Button, useDisclosure } from "@chakra-ui/react";
import DisplayText from "components/Common/DisplayText";
import OrderItemModal from "components/Modal/OrderItemModal";
import dayjs from "dayjs";
import React, { useState } from "react";
import colors from "utils/colors";
import { concatAddress } from "../../utils/orderUtils";
import OrderItemRow from "./OrderItemRow";

const OrderRow = ({ order, isLast }) => {
  const {
    id,
    status,
    subtotal,
    total,
    referralCode,
    newUserPromo,
    newUserPromoCode,
    highestPackage,
    createdAt,
    payment,
    shipping,
    items,
  } = order || {};

  const { onOpen, isOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <Tr>
        <Td
          pl="0px"
          borderColor={colors.borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <DisplayText label="ID" value={id} fontSize="sm" />
          <DisplayText
            label="Created At"
            value={dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")}
            fontSize="sm"
          />
          <DisplayText label="Status" value={status} fontSize="sm" />
        </Td>

        <Td
          borderColor={colors.borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Box>
            {/* {items?.map((item) => {
            return <OrderItemRow key={item._id} item={item} />;
          })} */}
            <Button
              onClick={() => {
                onOpen();
                setSelectedItem(items);
              }}
            >
              View Details
            </Button>
          </Box>
        </Td>

        <Td
          borderColor={colors.borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Box>
            <DisplayText label="Status" value={payment?.status} fontSize="sm" />
            <DisplayText
              label="Referral Code"
              value={referralCode || "-"}
              fontSize="sm"
            />
            <DisplayText label="Subtotal" value={subtotal} fontSize="sm" />
            <DisplayText
              label="New User Promo"
              value={newUserPromo}
              fontSize="sm"
            />
            <DisplayText label="Total" value={total} fontSize="sm" />
          </Box>
        </Td>

        <Td
          borderColor={colors.borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Box>
            <DisplayText label="Name" value={shipping?.name} fontSize="sm" />
            <DisplayText
              label="Address"
              value={concatAddress(shipping?.address)}
              fontSize="sm"
            />
            <DisplayText label="Phone" value={shipping?.phone} fontSize="sm" />
          </Box>
        </Td>
      </Tr>
      <OrderItemModal
        isOpen={isOpen}
        onClose={onClose}
        items={selectedItem}
        setItems={setSelectedItem}
      />
    </>
  );
};

export default OrderRow;
