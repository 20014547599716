import { Box, Text } from "@chakra-ui/react";
import React from "react";

const DisplayText = ({ label, value, ...textProps }) => {
  return (
    <Box>
      <Text as={"span"} {...textProps} fontWeight="bold">
        {label}
      </Text>{": "}
      <Text as={"span"} {...textProps}>
        {value}
      </Text>
    </Box>
  );
};

export default DisplayText;
