// import
import React, { Component }  from 'react';
import Dashboard from "pages/Dashboard/Dashboard.js";
import Tables from "pages/Dashboard/Tables.js";
import Billing from "pages/Dashboard/Billing.js";
import Profile from "pages/Dashboard/Profile.js";
import Users from 'pages/Dashboard/Users.js';
import Login from "pages/Auth/Login.js";
import Register from "pages/Auth/Register.js";
import {AiFillShop} from 'react-icons/ai';
import {MdVideogameAsset} from 'react-icons/md';
import {BsCashCoin} from 'react-icons/bs';

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import Orders from 'pages/Dashboard/Orders';
import Nfts from 'pages/Dashboard/Nfts';
import Cashout from 'pages/Dashboard/Cashout';

var dashRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: <HomeIcon color='inherit' />,
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    path: "/users",
    name: "Users",
    icon: <PersonIcon color='inherit' />,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: <AiFillShop color='inherit' />,
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/nfts",
    name: "Nfts",
    icon: <MdVideogameAsset color='inherit' />,
    component: Nfts,
    layout: "/admin",
  },
  {
    path: "/cashout",
    name: "Cashout History",
    icon: <BsCashCoin color='inherit' />,
    component: Cashout,
    layout: "/admin",
  },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: <StatsIcon color='inherit' />,
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   icon: <CreditIcon color='inherit' />,
  //   component: Billing,
  //   layout: "/admin",
  // },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
  },
];
export default dashRoutes;
